.home {
    background-image: url('https://media3.giphy.com/media/Y4ak9Ki2GZCbJxAnJD/giphy.gif?cid=ecf05e47bsmy69x4cbx7zhnjn6td8c44up7073csur5ztuj5&ep=v1_gifs_related&rid=giphy.gif&ct=g');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 85vh;
    margin-top: 74.2px;
    overflow-x: hidden;
    border: 2px solid black;
        
}



.details {
    color:rgb(235, 14, 187) ;
    font-size: 16px;
    margin-left: 210px;
    font-family: cursive;
    margin-top: 170px;
    word-spacing: 10px;
    line-height: 40px;
    text-indent: 100px;   
}

.details::first-letter {
    font-size: 40px;
}

@media screen and (max-width: 665px){
   
    .home {
        background-image: none;
        height: 80vh;
        margin-left: 3px;
    }
    .details {
        color: rgb(235, 14, 187);
        font-size: 12px;
        font-weight: bold;
        margin-left: 10px;
        margin-top: 50px;
        margin: 2px;
    }
    .icons {
        margin-left: 10px;
        
    }

    .homeTextPart {
        text-align: center;
        padding-top: 80px;
    }
}

@media screen and (min-width: 769px){
    
    .homeTextPart {
        padding-left: 80px;
    }
}

