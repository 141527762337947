.about {
    background-color: black;
}

.aboutTextPart {
    display: flex;
    align-items: center;
}
.downloadcv {
    color: white;
    background-color: rgb(211, 23, 111);
    

}

.downloadcv:hover {
background-color: transparent;
color: white;
border: 1px solid rgb(211, 23, 111);
}

@media screen and (max-width:769px) {
   .abtImg {
    width: 230px;
    margin-bottom: 20px;
    margin-left: 43px;
   }
   .about {
    border: 2px solid rgb(211, 23, 111);
    margin-left: 3px;
   }
   .downloadcv {
    margin: 5px;
   }
   
}
