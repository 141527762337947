.services {
    background-color: black;
    color: white;
}

hr {
    height: 2px;
    background-image: -webkit-linear-gradient(left, black,white,white,black);
}

.myserviceText {
    text-align: center;

}

.myserviceText span {
    color: rgb(211, 23, 111);
}

.serviceIcon {
    color: rgb(211, 23, 111) !important;
    margin-top: 12px;
}

.serviceIcon span {
    border-color:rgb(211, 23, 111) !important;
}

.service {
    transition: 1s;
}

.service:hover {
    transform: scale(1.2);
    background-color: rgb(211, 23, 111);
    cursor: pointer;
}

.service:hover .serviceIcon {
    color: white !important;
}

.service:hover .serviceIcon span {
   border-color: white !important;
}


@media screen and (max-width:665px) {
    .services {
        border:3px solid rgb(211, 23, 111);
        margin-left: 3px;
    }
}