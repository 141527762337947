.contact {
    background-color: black;
}

hr {
    height: 2px;
    background-image: -webkit-linear-gradient(left, black,white,white,black);
}

.contact button {
    color: rgb(211, 23, 111);
    border: 1px solid rgb(211, 23, 111);
    background-color: transparent;
}

.contact button:hover{
    color: white;
    background-color: rgb(211, 23, 111);
}

@media screen and (min-width:769px) {
    .mx-md-auto {
        width: 50%;
    }
    
}

@media screen and (max-width:665px) {
   .contact {
    margin-left: 3px;
    border: 3px solid rgb(211, 23, 111);
   }
    
}