.navbar {
    background-color: black !important;
    
}

.navbar-brand {
    color: rgb(235, 14, 187);
    font-size: xx-large;
    font-weight: bolder;
    font-family: 'Times New Roman', Times, serif;
}

.navbar li {
    padding: 0px 20px;
}

.navbar li a {
    color: white !important;
    font-weight: bold;
}

.navbar li a:hover {
    color: rgb(235, 14, 187) !important;
}


@media screen and (max-width:769px) {
    .navbar {
        width: 383px;
    }
}